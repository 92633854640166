<template>
  <div class="pa-2">
    <v-card class="pa-1 mb-2" flat  :loading="loadingstatus">
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="btnload"
          >Load Data
          
          <v-icon>mdi-file-pdf-outline</v-icon>
        </v-btn>

        <v-btn color="success" v-simple-print="'panAllData'"  v-if="desserts.length > 0"
          >Download
          
          <v-icon>mdi-file-pdf-outline</v-icon>
        </v-btn>

       

      
      </v-card-actions>
</v-card>


<v-card class="pa-1 mb-2" flat v-if="desserts.length > 0">
      <div id="panAllData">
       <table style="width:100%">
    
      <tbody>

     
  <tr>
                  <th class="thheader">SNO</th>
                  <th class="thheader">Photo</th>
                  <th class="thheader">Personal Details</th>
                  <th class="thheader">Address</th>
                  <th class="thheader">ID</th>
                  <th class="thheader">Company / Designation</th>
                  <th class="thheader">Card Details</th>
                  <!-- <th style="text-align: center;width: 10px !important;font-size: 9px;font-family: Arial;padding:8px 0px;border:1px solid #ccc;">Card Details</th> -->
                </tr>

          <tr  v-for="item in desserts" :key="item.sr_no">
            <td class="thdata" style="text-align: center; width: 10px">
              {{ item.sr_no }}
            </td>
            <td class="thdata" style="text-align: center; width: 10px !important">
              <img
                :src="$apiPath + item.photo"
                height="60px"
                width="60px"
                style="border-radius: 10px; border: 1px solid #ccc"
                type="button"
              />
            </td>

            <td class="thdata">
              Reg.No- {{ item.app_id }}<br />
              {{ item.firstName }} {{ item.lastName }}<br />
              {{ item.dob }} <br />
              {{ item.mobile }}
            </td>

            <td class="thdata">
              {{ item.addLine1 }}<br />
              {{ item.addLine2 }}
            </td>

            <td class="thdata">
              {{ item.idProofType }}<br />
              {{ item.idProofNumber }}
            </td>

            <td class="thdata" >
              {{ item.mediaName }}<br />
              {{ item.role_name }}
            </td>

            <td class="thdata">
              {{ item.card_name }}<br />
              {{ item.print_text }}
            </td>
          </tr>
       </tbody>
    
       </table>
      </div>
      </v-card>
    
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
//import axios from "axios";

export default {
  data() {
    return {
      desserts: [],
      ReportFor:'',
       loadingstatus: false,
    };
  },
  mounted() {
    
     this.$store.commit("setPageTitle", "Police Report");
    this.$store.commit("setPageTitleIcon", "mdi-file-chart");
  },
  methods: {
    exportPDF() {
      window.html2canvas = html2canvas;
      var doc = new jsPDF("p", "pt", "a4");
      doc.html(document.querySelector("#panAllData"), {
        callback: function (pdf) {
          pdf.save("PoliceReport.pdf");
        },
      });
    },
    btnload:function(){
this.Reload();
    },

       Reload: async function () {
      this.loadingstatus = true;
      this.overlay = true;
       

      await this.$axios
        .get(
            "ReportMedia/PoliceAccrdReport/" +
            this.$store.state.eventInfo.EventId + "/" + this.$store.state.eventInfo.VenueId
        )
        .then((res) => {
          console.log(res.data.result);
          this.desserts = res.data.result;
          this.loadingstatus = false;
        })
        .catch()
        .finally();
    },

  
  },
};
</script>
<style scoped>
.thheader {
  background-color: rgba(0, 132, 255, 0.658);
  text-align: center;
  width: 10px !important;
  font-size: 9px;
  font-family: Arial;
  padding: 8px 0px;
  border: 1px solid #ccc;
}
.thdata {
  text-align: center;
 
  padding: 8px 0px;
  border: 1px solid #ccc;
}
</style>


